<template>
  <div>
    <table
      v-if="!isSearching && tagSuggestions.length > 0"
      class="table is-fullwidth is-striped is-narrow"
    >
      <thead>
        <th>&nbsp;</th>
        <th>Tag</th>
        <th>Score</th>
        <th>Times validated</th>
        <th>Validation score</th>
      </thead>
      <tbody>
        <tr v-for="(tagSuggestion, index) in tagSuggestions" :key="index">
          <td></td>
          <td>
            <span class="tag is-link">{{ tagSuggestion.RecTag }}</span>
          </td>
          <td>{{ tagSuggestion.Score }}</td>
          <td>{{ tagSuggestion.NrOfValidations }}</td>
          <td>{{ tagSuggestion.Validated }}</td>
        </tr>
      </tbody>
    </table>

    <Message
      v-if="!isSearching && tagSuggestions.length === 0"
      :message="'No associated tags found'"
    />
  </div>
</template>

<script>
import tagProvider from '@/providers/tag'

const Message = () => import('@/components/UI/Message')

export default {
  components: {
    Message,
  },

  props: {
    tagId: {
      default: 0,
      type: Number,
    },
  },

  data() {
    return {
      isSearching: false,
      searchTerm: '',
      tag: null,
      tagSuggestions: [],
    }
  },

  computed: {},

  mounted() {
    this.getTagSuggestions()
  },

  methods: {
    getTagSuggestions() {
      let self = this

      if (!self.isSearching) {
        self.isSearching = true

        tagProvider.methods
          .getRelatedTags(this.tagId, this.searchTerm)
          .then((response) => {
            if (response.status === 200) {
              self.tagSuggestions = response.data
            }
          })
          .finally(() => {
            self.isSearching = false
          })
      }
    },
  },
}
</script>

<style lang="scss" scoped></style>
